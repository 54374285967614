
export const TEXTOS = [
    {	 id: 	'txtMapa'	,es:	'Mapa'	, en:	'Map'	, it:	'Mappa'	 , fr:	'Carte'		},
    {	 id: 	'txtBonoPT'	,es:	'Bono Plaza de Torneos'	, en:	'Bonus Torunament Square'	, it:	'Bonus Arena'	 , fr:	'Bonus de la Place du Tournoi'		},
    {	 id: 	'txtNormal'	,es:	'Normal'	, en:	'Normal'	, it:	'Normale'	 , fr:	'Normal'	,	},
    {	 id: 	'txtDoble'	,es:	'Doble'	, en:	'Double'	, it:	'Doppio'	 , fr:	'Double'	,	},
    {	 id: 	'txtVel'	,es:	'Velocidad Server'	, en:	'Server Speed'	, it:	'Server Speed'	 , fr:	'Vitesse du serveur'	,	},
    {	 id: 	'txtCoordsA'	,es:	'Coordenadas Agresor'	, en:	'Coordinates Attacker'	, it:	'Coordinate Attacante'	 , fr:	"Coordonnées de l'attaquant"	,	},
    {	 id: 	'txtCoordsD'	,es:	'Coordenadas Defensor'	, en:	'Coordinates Defender'	, it:	'Coordinate Difensore'	 , fr:	'Coordonnées du défenseur'	,	},
    {	 id: 	'txtPT'	,es:	'Plaza de Torneos'	, en:	'Tournament Square'	, it:	'Arena'	 , fr:	'Place du Tournoi'	,	},
    {	 id: 	'txtReliVel'	,es:	'Reliquia de Velocidad'	, en:	'Artefact Faster Troops'	, it:	'Artefatto Velocità'	 , fr:	'Artéfact Vitesse'	,	},
    {	 id: 	'txtBotasH'	,es:	'Botas del Héroe'	, en:	'Heroe Boots'	, it:	"Stivale dell'Eroe"	 , fr:	'Bottes du Héros'	,	},
    {	 id: 	'txtBotasH1'	,es:	'Ninguna'	, en:	'Neither'	, it:	'Nessuno'	 , fr:	'Aucun'	,	},
    {	 id: 	'txtBotasH2'	,es:	'del Mercenario'	, en:	'Mercenary'	, it:	'del Mercenario'	 , fr:	'Bottes du Mercenaire'	,	},
    {	 id: 	'txtBotasH3'	,es:	'del Guerrero'	, en:	'Warrior'	, it:	'del Guerriero'	 , fr:	'Bottes du Guerrier'	,	},
    {	 id: 	'txtBotasH4'	,es:	'del Arconte'	, en:	'Archon'	, it:	"dell'Arconte"	 , fr:	"Bottes de l'Archer"	,	},
    {	 id: 	'txtAPaso'	,es:	'A paso de'	, en:	'With'	, it:	'A passo di'	 , fr:	'Avec'	,	},
    {	 id: 	'txtAPaso2'	,es:	'tarda'	, en:	'it tooks'	, it:	'ci mette'	 , fr:	'Cela prend'	,	},
    {	 id: 	'txtHoraAtaque'	,es:	'Hora de llegada:' 	, en:	'At what time arrives the attack?' 	, it:	"A che ora arriva l'attacco?" 	 , fr:	"À quelle heure l'attaque arrive-t-elle ?"	,	},
    {	 id: 	'txtLanzado'	,es:	'Hora de salida:'	, en:	'The attack was launched at'	, it:	"L'attacco è stato inviato alle"	 , fr:	"L'attaque a été lancé à"	,	},
    {	 id: 	'txtCalcular'	,es:	'Calcular'	, en:	'Calculate'	, it:	'Calcolare'	 , fr:	'Calculer'	,	},
    {	 id: 	'idioma'	,es:	'es'	, en:	'en'	, it:	'it'	 , fr:	'fr'	,	},
    {	 id: 	'TropaA1'	,es:	'Porra'	, en:	'Clubswinger'	, it:	'Combattente'	 , fr:	'Combattant au gourdin'	,	},
    {	 id: 	'TropaA2'	,es:	'Lancero'	, en:	'Spearman'	, it:	'Alabardiere'	 , fr:	'Combattant à la lance'	,	},
    {	 id: 	'TropaA3'	,es:	'Hacha'	, en:	'Axeman'	, it:	'Combattente con ascia'	 , fr:	'Combattant à la hache'	,	},
    {	 id: 	'TropaA4'	,es:	'Emisario'	, en:	'Scout'	, it:	'Esploratore'	 , fr:	'Eclaireur'	,	},
    {	 id: 	'TropaA5'	,es:	'Paladín'	, en:	'Paladin'	, it:	'Paladino'	 , fr:	'Paladin'	,	},
    {	 id: 	'TropaA6'	,es:	'Teutón'	, en:	'Teutonic Knight'	, it:	'Cavaliere Teutonico'	 , fr:	'Cavalier Teuton'	,	},
    {	 id: 	'TropaA7'	,es:	'Ariete'	, en:	'Ram'	, it:	'Ariete'	 , fr:	'Bélier'	,	},
    {	 id: 	'TropaA8'	,es:	'Catapulta'	, en:	'Catapult'	, it:	'Catapulta'	 , fr:	'Catapulte'	,	},
    {	 id: 	'TropaA9'	,es:	'Cabecilla'	, en:	'Chief'	, it:	'Comandante'	 , fr:	'Chef de tribu'	,	},
    {	 id: 	'TropaA10'	,es:	'Colono'	, en:	'Settler'	, it:	'Colono'	 , fr:	'Colon'	,	},
    {	 id: 	'TropaGer1'	,es:	'Porra'	, en:	'Clubswinger'	, it:	'Combattente'	 , fr:	'Combattant au gourdin'	,	},
    {	 id: 	'TropaGer2'	,es:	'Lancero'	, en:	'Spearman'	, it:	'Alabardiere'	 , fr:	'Combattant à la lance'	,	},
    {	 id: 	'TropaGer3'	,es:	'Hacha'	, en:	'Axeman'	, it:	'Combattente con ascia'	 , fr:	'Combattant à la hache'	,	},
    {	 id: 	'TropaGer4'	,es:	'Emisario'	, en:	'Scout'	, it:	'Esploratore'	 , fr:	'Eclaireur'	,	},
    {	 id: 	'TropaGer5'	,es:	'Paladín'	, en:	'Paladin'	, it:	'Paladino'	 , fr:	'Paladin'	,	},
    {	 id: 	'TropaGer6'	,es:	'Teutón'	, en:	'Teutonic Knight'	, it:	'Cavaliere Teutonico'	 , fr:	'Cavalier Teuton'	,	},
    {	 id: 	'TropaGer7'	,es:	'Ariete'	, en:	'Ram'	, it:	'Ariete'	 , fr:	'Bélier'	,	},
    {	 id: 	'TropaGer8'	,es:	'Catapulta'	, en:	'Catapult'	, it:	'Catapulta'	 , fr:	'Catapulte'	,	},
    {	 id: 	'TropaGer9'	,es:	'Cabecilla'	, en:	'Chief'	, it:	'Comandante'	 , fr:	'Chef de tribu'	,	},
    {	 id: 	'TropaGer10'	,es:	'Colono'	, en:	'Settler'	, it:	'Colono'	 , fr:	'Colon'	,	},
    {	 id: 	'TropaRoma1'	,es:	'Legionario'	, en:	'Legionnaire'	, it:	'Legionario'	 , fr:	'Légionnaire'	,	},
    {	 id: 	'TropaRoma2'	,es:	'Pretoriano'	, en:	'Praetorian'	, it:	'Pretoriano'	 , fr:	'Prétorien'	,	},
    {	 id: 	'TropaRoma3'	,es:	'Imperano'	, en:	'Imperian'	, it:	'Imperiano'	 , fr:	'Impérian'	,	},
    {	 id: 	'TropaRoma4'	,es:	'Legati'	, en:	'Equites Legati'	, it:	'Emissario a cavallo'	 , fr:	'Equites Legati'	,	},
    {	 id: 	'TropaRoma5'	,es:	'Imperatoris'	, en:	'Equites Imperatoris'	, it:	'Cavaliere del Generale'	 , fr:	'Equites Imperatoris'	,	},
    {	 id: 	'TropaRoma6'	,es:	'Caesaris'	, en:	'Equites Caesaris'	, it:	'Cavaliere di Cesare'	 , fr:	'Equites Caesaris'	,	},
    {	 id: 	'TropaRoma7'	,es:	'Ariete'	, en:	'Ram'	, it:	'Ariete'	 , fr:	'Bélier'	,	},
    {	 id: 	'TropaRoma8'	,es:	'Catapulta'	, en:	'Catapult'	, it:	'Onagro'	 , fr:	'Catapulte de feu'	,	},
    {	 id: 	'TropaRoma9'	,es:	'Senador'	, en:	'Senator'	, it:	'Senatore'	 , fr:	'Sénateur'	,	},
    {	 id: 	'TropaRoma10'	,es:	'Colono'	, en:	'Settler'	, it:	'Colono'	 , fr:	'Colon'	,	},
    {	 id: 	'TropaGal1'	,es:	'Falange'	, en:	'Phalanx'	, it:	'Falange'	 , fr:	'Phalange'	,	},
    {	 id: 	'TropaGal2'	,es:	'Espada'	, en:	'Swordsman'	, it:	'Combattente con spada'	 , fr:	"Combattant à l'épée",	},
    {	 id: 	'TropaGal3'	,es:	'Batidor'	, en:	'Pathfinder'	, it:	'Ricognitore'	 , fr:	'Éclaireur'	,	},
    {	 id: 	'TropaGal4'	,es:	'Trueno'	, en:	'Theutates Thunder'	, it:	'Fulmine di Teutates'	 , fr:	'Éclair de Toutatis'	,	},
    {	 id: 	'TropaGal5'	,es:	'Druida'	, en:	'Druidrider'	, it:	'Cavaliere druido'	 , fr:	'Cavalier druide'	,	},
    {	 id: 	'TropaGal6'	,es:	'Eduo'	, en:	'Haeduan'	, it:	'Paladino Haeduan'	 , fr:	'Hédouin'	,	},
    {	 id: 	'TropaGal7'	,es:	'Ariete'	, en:	'Ram'	, it:	'Ariete'	 , fr:	'Bélier'	,	},
    {	 id: 	'TropaGal8'	,es:	'Catapulta'	, en:	'Catapult'	, it:	'Trabucco'	 , fr:	'Catapulte de Guerre'	,	},
    {	 id: 	'TropaGal9'	,es:	'Cacique'	, en:	'Chieftain'	, it:	'Capo tribù'	 , fr:	'Chef'	,	},
    {	 id: 	'TropaGal10'	,es:	'Colono'	, en:	'Settler'	, it:	'Colono'	 , fr:	'Colon'	,	},
    {	 id: 	'TropaEgp1'	,es:	'Esclavo'	, en:	'Slave Militia'	, it:	'Schiavo Miliziano'	 , fr:	'Milicien esclave'	,	},
    {	 id: 	'TropaEgp2'	,es:	'Guardia Ash'	, en:	'Ash Warden'	, it:	'Guardiano delle Rovine'	 , fr:	'Sentinelle'	,	},
    {	 id: 	'TropaEgp3'	,es:	'Guerreros Khopes'	, en:	'Khopesh Warrior'	, it:	'Guerriero con Khopesh'	 , fr:	'Soldat au khopesh'	,	},
    {	 id: 	'TropaEgp4'	,es:	'Explorador Sopdu'	, en:	'Sopdu Explorer'	, it:	'Scopritore di Sopdu'	 , fr:	'Explorateur de Sopdou'	,	},
    {	 id: 	'TropaEgp5'	,es:	'Guarda Osiris'	, en:	'Anhur Guard'	, it:	'Guardia di Onuris'	 , fr:	"Garde d'Anhour"	,	},
    {	 id: 	'TropaEgp6'	,es:	'Carro de Reshef'	, en:	'Resheph Chariot'	, it:	'Cocchiere di Reshef'	 , fr:	'Char de Reshep'	,	},
    {	 id: 	'TropaEgp7'	,es:	'Ariete'	, en:	'Ram'	, it:	'Ariete'	 , fr:	'Bélier'	,	},
    {	 id: 	'TropaEgp8'	,es:	'Catapulta'	, en:	'Catapult'	, it:	'Catapulta di Pietre'	 , fr:	'Catapulte de pierre'	,	},
    {	 id: 	'TropaEgp9'	,es:	'Visir'	, en:	'Nomarch'	, it:	'Governatore'	 , fr:	'Nomarque'	,	},
    {	 id: 	'TropaEgp10'	,es:	'Colono'	, en:	'Settler'	, it:	'Colono'	 , fr:	'Colon'	,	},
    {	 id: 	'TropaHun1'	,es:	'Mercenario'	, en:	'Mercenary'	, it:	'Mercenario'	 , fr:	'Mercenaire'	,	},
    {	 id: 	'TropaHun2'	,es:	'Arquero'	, en:	'Bowman'	, it:	'Arciere'	 , fr:	'Archer'	,	},
    {	 id: 	'TropaHun3'	,es:	'Observador'	, en:	'Spotter'	, it:	'Osservatore'	 , fr:	'Guetteur'	,	},
    {	 id: 	'TropaHun4'	,es:	'Estepario'	, en:	'Steppe Rider'	, it:	'Cavaliere della Steppa'	 , fr:	'Cavalier des steppes'	,	},
    {	 id: 	'TropaHun5'	,es:	'Certero'	, en:	'Marksman'	, it:	'Tiratore Scelto'	 , fr:	'Archer monté'	,	},
    {	 id: 	'TropaHun6'	,es:	'Merodeador'	, en:	'Marauder'	, it:	'Devastatore'	 , fr:	'Maraudeur'	,	},
    {	 id: 	'TropaHun7'	,es:	'Ariete'	, en:	'Ram'	, it:	'Ariete'	 , fr:	'Bélier'	,	},
    {	 id: 	'TropaHun8'	,es:	'Catapulta'	, en:	'Catapult'	, it:	'Catapulta'	 , fr:	'Catapulte'	,	},
    {	 id: 	'TropaHun9'	,es:	'Logades'	, en:	'Logades'	, it:	'Logades'	 , fr:	'Logades'	,	},
    {	 id: 	'TropaHun10'	,es:	'Colono'	, en:	'Settler'	, it:	'Colono'	 , fr:	'Colon'	,	},
    {	 id: 	'HERRAMIENTAS'	,es:	'HERRAMIENTAS'	, en:	'TOOLS'	, it:	'STRUMENTI'	 , fr:'OUTILS'	,	},
    {	 id: 	'funcion1'	,es:	"Calcular Tiempos"	, en:	'Times calculator'	, it:	'Calcolare Tempi'	 , fr:	'Calculer Temps'	,	},
    {	 id: 	'funcion2'	,es:	'Calcular Phalanx'	, en:	'Calculate Phalanx'	, it:	'Calcolare Falangiata'	 , fr:	"Calculer le retour d'off"	,	},
    {	 id: 	'funcion3'	,es:	'Calcular Plaza de Torneos'	, en:	'Calculate Tournament Square'	, it:	'Calcolare Livello Arena'	 , fr:	"Place du Tournoi de l'adversaire"	,	},
    {	 id: 	'modo'	,es:	'Modo Nocturno'	, en:	'Night Mode'	, it:	'Modo Notte'	 , fr:	'Mode nuit'	,	},
    {	 id: 	"txtManoIzq"	,es:	'Tipo de Estandarte'	, en:	"Standard"	, it:	"Bandiera"	 , fr:	"Bannière"	,	},
    {	 id: 	"txtManoIzq1"	,es:	"Ninguno"	, en:	'Neither'	, it:	'Nessuno'	 , fr:	'Aucun'	,	},
    {	 id: 	"txtManoIzq2"	,es:	"Estandarte Pequeño"	, en:	"Small Standard"	, it:	"Piccola Bandiera"	 , fr:	"Petite bannière"	,	},
    {	 id: 	"txtManoIzq3"	,es:	"Estandarte"	, en:	"Standard"	, it:	"Bandiera"	 , fr:	"Bannière"	,	},
    {	 id: 	"txtManoIzq4"	,es:	'Estandarte Grande'	, en:	"Great Standard"	, it:	"Grander Bandiera"	 , fr:	"Grande bannière"	,	},
    {	 id: 	"textObjH"	,es:	'Objetos del Héroe'	, en:	'Equipment'	, it:	"Oggetti dell'Eroe"	 , fr:	'Objets du Héros'	,	},
    {	 id: 	"textObjH1"	,es:	'Ninguno'	, en:	'Neither'	, it:	'Nessuno'	 , fr:	'Aucun'	,	},
    {	 id: 	"textObjH2"	,es:	'Mapa pequeño'	, en:	'Small Map'	, it:	'Piccola mappa'	 , fr:	'Petite Carte'	,	},
    {	 id: 	"textObjH3"	,es:	'Mapa'	, en:	'Map'	, it:	'Mappa'	 , fr:	'Carte'	,	},
    {	 id: 	"textObjH4"	,es:	'Mapa grande'	, en:	'Large Map'	, it:	'Grande mappa'	 , fr:	'Grande Carte'	,	},
    {	 id: 	"txtVuelve"	,es:	'Su ofensiva vuelve a las'	, en:	'The troops returns at'	, it:	'Le truppe ritorneranno alle'	 , fr:	'Les troupes reviennent à'	,	},
    {	 id: 	"txtFalta"	,es:	"Cuanto falta para el ataque?"	, en:	'How long before the attack?'	, it:	"Quanto manca per l'attacco?"	 , fr:	"Combien de temps avant l'attaque"	,	},
    {	 id: 	"txtBuffer"	,es:	"Buffer (tiempo sin ver la ficha)"	, en:	'Buffer (time without seeing the account)'	, it:	"Buffer (tempo senza vedere l'account)"  , fr:	"Temps estimé avant d'avoir vu l'attaque"	,	},
    {	 id: 	"txtRazaA"	,es:	"Raza del agresor"	, en:	'Attacker tribe'	, it:	'Tribù attacante'	 , fr:	"Peuple de l'attaquant"	,	},
    {	 id: 	"txtRazaGer"	,es:	'Germano'	, en:	'Teuton'	, it:	'Teutone'	 , fr:	'Germains'	,	},
    {	 id: 	"txtRazaRom"	,es:	'Romano'	, en:	'Roman'	, it:	'Romani'	 , fr:	'Romains'	,	},
    {	 id: 	"txtRazaGal"	,es:	'Galo'	, en:	'Gaul'	, it:	'Galli'	 , fr:	'Gaulois'	,	},
    {	 id: 	"txtRazaHun"	,es:	'Huno'	, en:	'Hun'	, it:	'Unni'	 , fr:	'Huns'	,	},
    {	 id: 	"txtRazaEgp"	,es:	'Egipcio'	, en:	'Egyptian'	, it:	'Egiziani'	 , fr:	'Égyptiens'	,	},
    {	 id: 	"txtPosiblesPT"	,es:	"Posibles Niveles de PT"	, en:	"Possible levels Tournament Square"	, it:	"Livelli possibili dell'Arena"	 , fr:	"Niveaux possibles Place du Tournoi"	,	},

    



    
 
   
];