import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { TEXTOS } from './data/data.textos';
import { TextosInt } from './interfaces/texto.interfaces';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit{

   appPages = [
    {
      title: 'Calculadora de Tiempos',
      url: '/app/home',
      icon: 'stopwatch'
    },
    {
      title: 'Calcular Phalanx',
      url: '/app/phalanx',
      icon: 'git-pull-request'
    },
    {
      title: 'Calcular Plaza de Torneos',
      url: '/app/plazat',
      icon: 'navigate'  
    },  
  ];
 
  dark = false;
  textos:TextosInt[] = [];
  idioma:string;
  funcion1:string = 'Calcular Tiempos';
  funcion2:string = 'Calcular Phalanx';
  funcion3:string = 'Calcular Plaza de Torneos';
 
  constructor(
   
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private toastCtrl: ToastController,
    
  ) {
    this.initializeApp();
  }
  guardarStorage() {

    if ( this.platform.is('cordova')  ){
      // Celular

      this.storage.set('idioma', this.textos[19][this.idioma]);
        //Menú Lateral
      this.storage.set('funcion1', this.textos[81][this.idioma]);
      this.storage.set('funcion2', this.textos[82][this.idioma]);
      this.storage.set('funcion3', this.textos[83][this.idioma]);
      this.storage.set('Herramientas', this.textos[80][this.idioma]);
      this.storage.set('modo', this.textos[84][this.idioma]);
      //Hoja HorasLanzamiento




    } else {
      // Escritorio
      localStorage.setItem('idioma', this.textos[19][this.idioma]);
      //Menú Lateral
      localStorage.setItem('idioma', this.textos[19][this.idioma]);
      localStorage.setItem('funcion1', this.textos[81][this.idioma]);
      localStorage.setItem('funcion2', this.textos[82][this.idioma]);
      localStorage.setItem('funcion3', this.textos[83][this.idioma]);
      localStorage.setItem('Herramientas', this.textos[80][this.idioma]);
      localStorage.setItem('modo', this.textos[84][this.idioma]);
      //Hoja HorasLanzamiento
    }

  }

  cargarStorage() {

    return new Promise( (resolve, reject) => {


    if ( this.platform.is('cordova')  ){
      // Celular

      this.storage.get('idioma').then( val => {

        if ( val ) { this.idioma = val;   resolve(true);
        // Menú lateral
        this.storage.get('funcion1').then( val => { this.textos[81][this.idioma] = val; });
        this.storage.get('funcion2').then( val => { this.textos[82][this.idioma] = val; });
        this.storage.get('funcion3').then( val => { this.textos[83][this.idioma] = val; });
        this.storage.get('Herramientas').then( val => { this.textos[80][this.idioma] = val; });
        this.storage.get('modo').then( val => { this.textos[84][this.idioma] = val; });
        //Hoja HorasLanzamiento


        }else {
          resolve(false);
        }

      });


    } else {
      // Escritorio
      if ( localStorage.getItem('idioma')){ this.idioma = localStorage.getItem('idioma');
        resolve(true);

      }else {
        resolve(false);
      }


    }


  });



}
async ngOnInit() {
  this.cargarStorage();
 this.textos = TEXTOS;
  
    }



  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

asignarIdioma(){
 // this.Herramientas = this.textos[80][this.idioma];
//  this.funcion1 = this.textos[81][this.idioma];
 // this.funcion2 = this.textos[82][this.idioma];
 // this.funcion3 = this.textos[83][this.idioma];
 // this.modo = this.textos[84][this.idioma];

  this.guardarStorage()
}

 

}
